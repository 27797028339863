import { BasicButtonToggling } from "./basic-button-toggling";
import { nodeListToArray } from "../functions/helpers/nodeListToArray";
import { setCookie, expireCookie } from "../functions/helpers/cookie";

export class GoogleTranslate extends BasicButtonToggling {
    private languageChangers: Array<HTMLButtonElement>;
    private languageSelectors: Array<HTMLSelectElement>;
    private translateToggler: HTMLButtonElement;
    constructor() {
        super();
        this.languageSelectors = nodeListToArray(document.querySelectorAll('[data-language-selector]'));
        this.languageChangers = (nodeListToArray(document.querySelectorAll('[data-language-changer]')) as Array<HTMLButtonElement>); 
        this.translateToggler = document.querySelector('[data-translate-toggler]') as HTMLButtonElement;
        this.init();
    }
    init() {
        this.activateChangers();
        this.activateToggler();
    }
    activateToggler() {
        this.translateToggler.addEventListener('click', this.handleClick, false);
    }
    activateChangers() {
        this.languageChangers.forEach(changer => {
            changer.addEventListener('click', e => {
                this.changeLanguage(changer.dataset.language);
            });
        });
        this.languageSelectors.forEach(languageSelector => {
            languageSelector.addEventListener('change', e => {
                this.changeLanguage(languageSelector.value);
            });
        });
    }
    changeLanguage(language: string) {
        if (language === 'en') {
            expireCookie('googtrans');
        }
        else {
            setCookie('googtrans', `/en/${language}`, .5);
        }
        location.reload();
    }
}


//jQuery(function($) { 

//	$('.js-change-language').click(function(event) {
//		event.preventDefault();
		
//		if ($(this).data('language') === 'en') {

//			Cookies.expire('googtrans');
//			Cookies.expire('googtrans', {domain: '.www.strathmore.org'});
//			Cookies.expire('googtrans', {domain: '.strathmore.org'});
//			/*Cookies.expire('googtrans', {domain: '.lynch2.com'})
//			Cookies.expire('googtrans', {domain: '.localdev'})*/

//		} else {

//			Cookies.set('googtrans', '/en/' + $(this).data('language'), {expires: 400 * 60 * 24 * 60});

//		}

//		location.reload();

//	});

//	$('.js-bloom-translate-toggle').click(function(event) {

//		if($(".js-bloom-translate-toggle").hasClass("translate-open")){
//			$(".js-bloom-languages-container").hide();
//			$(".js-bloom-translate-toggle").removeClass("translate-open");
//		}
//		else {
//			$(".js-bloom-languages-container").show();
//			$(".js-bloom-translate-toggle").addClass("translate-open");
//		}
		
//	});

//});