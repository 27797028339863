import { nodeListToArray } from '../functions/helpers/nodeListToArray';
import * as Cookie from '../functions/helpers/cookie';

export class Alert {
    private alerts = nodeListToArray(document.querySelectorAll('[data-alert]'));
    private cookie = '';
    constructor() {
        if (this.alerts.length > 0) {
            this.alerts.forEach(alert => {

                const alertCookieName = alert.getAttribute('data-alert-cookie');

                let isDismissed = (Cookie.getCookie(alertCookieName) == null) ? false : true;
                const alertCloseButton = alert.querySelector('[data-alert-close]');
                alertCloseButton.addEventListener('click', () => {
                    this.closeAlert(alertCloseButton);
                });

                if (!isDismissed) {
                    this.showAlert(alert);
                }
                else {
                    this.hideAlert(alert)
                }
            });
        }
    }

    showAlert(alertToShow: HTMLElement) {
        alertToShow.setAttribute('aria-hidden', 'false');
        alertToShow.classList.remove('hide');
    }

    hideAlert(alertToClose: HTMLElement) {
        alertToClose.setAttribute('aria-hidden', 'true');
        alertToClose.classList.add('hide');
    }

    closeAlert(alertCloseButton) {
        const alertToClose = document.getElementById(alertCloseButton.getAttribute('data-alert-close'));
        const alertCookieName = alertToClose.getAttribute('data-alert-cookie');
        this.cookie = Cookie.getCookie(alertCookieName);
        if (!this.cookie) {
            this.hideAlert(alertToClose);
            Cookie.setCookie(alertCookieName, true, 1);
            this.hideAlert(alertToClose);
        }
       
    }

}
