export function scrollToTop() {
    let scrollToTopBtn = document.querySelector('[data-scrollTop]');

    if (scrollToTopBtn != null) {
        scrollToTopBtn.addEventListener('click', function () {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        });
    }

}