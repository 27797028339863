import { nodeListToArray } from '../functions/helpers/nodeListToArray';
import { type } from 'os';

export class DonationComponent {

    donationApiEndpoint: string;
    donationCta: HTMLElement;
    donationBtns: any;

    constructor() {
        this.donationApiEndpoint = '/umbraco/api/donationapi/adddonation';
        this.donationBtns = nodeListToArray(document.querySelectorAll('[data-donate-btn]'));
        this.donationCta = document.getElementById('donationsubmitCta-js');
        if (this.donationCta) {
            this.donationCta.addEventListener('click', this.donationCtaClick.bind(this));
        }
        if (this.donationBtns) {
            this.donateBtns();
        }
    }

    donationCtaClick(e) {
        let donationInput = document.getElementById('donationinput-js') as HTMLInputElement;
        let fundIdInput = document.getElementById('donationFundId-js') as HTMLInputElement;
        let tnewFundTypeInput = document.getElementById('donationTnewFundType-js') as HTMLInputElement;

        if (donationInput && fundIdInput && tnewFundTypeInput) {
            let donationValue = donationInput.value.replace("$", "");
            let fundId = fundIdInput.value;
            let tnewFundType = tnewFundTypeInput.value;
            if (donationValue && donationValue !== "" && donationValue !== "0" && fundId !== "0" && tnewFundType !== "0") {
                this.addDonation(donationValue, fundId, tnewFundType);
            }
            else {
                this.showDonationError();
            }
        }
        // Show error if all fields are not found
        else {
            this.showDonationError();
        }
    }

    addDonation(donationValue: string, fundId: string, tnewFundType: string) {
        var request = { "donationAmount": donationValue, "fundId": fundId, "tnewFundType": tnewFundType };
        fetch(this.donationApiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        })
            .then(response => response.json())
            .then(data => this.handleDonationResponse(data));
    }

    handleDonationResponse(donationResponse: any) {
        let donationInput = document.getElementById('donationinput-js') as HTMLInputElement;
        if (donationResponse.donationSuccess) {
            window.location.href = donationResponse.redirectUrl;
        }
        else {
            this.showDonationError();
            donationInput.focus();
        }
    }

    showDonationError() {
        let donationError = document.getElementById('donationErrorMessage-js');
        if (donationError.classList.contains("hide"))
            donationError.classList.remove("hide");
    }

    donateBtns() {
        let donationInput = (document.getElementById('donationinput-js') as HTMLInputElement);
        this.donationBtns.forEach(el => {
            el.addEventListener('click', function () {
                const buttonValue = el.textContent as string;
                const formattedValue = buttonValue.replace(/[^0-9\.-]+/g, "");
                donationInput.value = formattedValue;
            })
        })

    }
}