//import isTouchDevice from './is-touch';
import { nodeListToArray } from '../functions/helpers/nodeListToArray';
import throttle = require('lodash.throttle');

const menuSlideStateAttribute = 'data-nav-is-showing';
const aceNav = document.getElementById('calendarNav');

export class mainNavigation {
    hideClass: string;
    visibleClass: string;
    isCollapsed: boolean;
    headerElement: HTMLElement;
    headerHeight: number;
    lastPos: number;
    curPos: number;
    nav: HTMLElement;
    navContainer: HTMLElement;
    setForTouchscreen: Function;
    activeLink: HTMLElement;
    topNavs: Array<any>;
    translateMenu: HTMLElement;
    navIsShowing: boolean;
    lastScrollPosition: number;
    menuHeight: number;
    constructor(navigation: HTMLElement) {
        this.hideClass = 'collapsed';
        this.visibleClass = 'visible';
        this.isCollapsed = false;

        this.headerElement = document.querySelector('section') as HTMLElement; //first section on page

        this.nav = navigation;
        this.navContainer = document.querySelector('header') as HTMLElement;
        this.activeLink = null;

        this.topNavs = Array.prototype.slice.call(this.nav.querySelectorAll('.nav-item-li0.has-children'));
        this.translateMenu = document.querySelector('[data-translate-container]') as HTMLElement;
        const translateMenu = this.translateMenu;
        this.calendarNavInit();
        this.setForTouchscreen = () => {
            this.topNavs.forEach((link, index) => {
                let anchor = link.querySelector('a');
                anchor.addEventListener('click', e => {
                    e.preventDefault();
                    if (anchor === this.activeLink) {
                        window.location.href = anchor.href;
                    }
                    else {
                        this.activeLink = anchor;
                        link.classList.add('focused');
                    }
                })
            })
        }
        this.menuHeight = this.nav.offsetHeight;
        //deal with focus/keyboards     
        this.topNavs.forEach((topNav) => {
            let childTags = Array.prototype.slice.call(topNav.querySelectorAll('a'));
            childTags.forEach((childTag) => {
                childTag.addEventListener('focus', () => {
                    if (!topNav.classList.contains('focused')) {
                        topNav.classList.add('focused');
                    }
                    if (this.isCollapsed) {
                        this.navContainer.classList.remove(this.hideClass);
                        this.isCollapsed = false;
                        this.navContainer.classList.add(this.visibleClass);
                    }
                });
                childTag.addEventListener('blur', () => {
                    if (!topNav.contains(document.activeElement)) {
                        topNav.classList.remove('focused');
                    }
                });
            });
        });

        // Close Section If Clicked Outside
        this.topNavs.forEach((topNav) => {
            const main = document.getElementById('main');

            main.addEventListener('click', e => {
                if (topNav.classList.contains('open')) {
                    this.toggleSection(topNav);
                }
                else if (translateMenu.classList.contains('active')) {
                    translateMenu.setAttribute('aria-hidden', 'true');
                    translateMenu.classList.toggle('active');
                }
            })
        })

        const topLevel = Array.prototype.slice.call(this.nav.querySelectorAll('.nav-main-li0.has-children'));
        topLevel.forEach(navSection => {
            const trigger = navSection.querySelector('[data-nav-trigger]');

            trigger.addEventListener('click', e => {
                e.preventDefault();

                let parentSection = e.target.parentNode.parentNode;
                let openSection = this.nav.querySelector('.nav-main-li0.open');

                if (openSection) {
                    if (openSection == parentSection) {
                        this.toggleSection(navSection);
                    }
                    else {
                        this.toggleSection(openSection);
                        this.toggleSection(navSection);
                    }

                }
                else {
                    this.toggleSection(navSection);
                }

            });

        })
    }

    toggleSection(section) {
        const subnav = section.querySelector('.nav-main-ul1');
        const subnavBtn = section.querySelector('.nav-main-ul0 .nav-item-btn');

        if (section.classList.contains('closed')) {
            section.classList.remove('closed');
            section.classList.add('open');
            subnav.setAttribute('aria-hidden', 'false');
            subnavBtn.setAttribute('aria-hidden', 'false');
        }
        else {
            section.classList.remove('open');
            section.classList.add('closed');
            subnav.setAttribute('aria-hidden', 'true');
            subnavBtn.setAttribute('aria-hidden', 'true');
        }
    }

    initPos() {
        this.headerHeight = Math.min((this.headerElement.getBoundingClientRect().bottom + window.pageYOffset), 500);
        this.curPos = window.pageYOffset;
        this.lastPos = this.curPos;
        this.setSubnavHeights();
    }

    setSubnavHeights() {
        //smoother animated dropdowns
        this.topNavs.forEach(topNav => {
            const subNav = topNav.querySelector('.nav-main-ul1');
            subNav.classList.add('measuring');
            subNav.style.height = subNav.clientHeight + 'px';
            subNav.classList.remove('measuring');
        })
    }

    slideDown = () => {
        this.navIsShowing = true;
        aceNav.setAttribute(menuSlideStateAttribute, 'true');
    }

    slideUp = () => {
        this.navIsShowing = false;
        aceNav.setAttribute(menuSlideStateAttribute, 'false');
    }


    scrollHandler = () => {
        const currentPosition = window.pageYOffset;
        const delta = currentPosition - this.lastScrollPosition;
        this.lastScrollPosition = currentPosition;

        if (this.navIsShowing) {
            if (delta > 0 && currentPosition > (this.menuHeight + aceNav.offsetHeight / 2 )) {
                this.slideUp();
            }
        }
        else if (delta < 0 || currentPosition < this.menuHeight) {
            this.slideDown();
        }

    }

    calendarNavInit = () => {
        if (aceNav) {
            this.slideDown();
            document.addEventListener('scroll', throttle(() => {
                this.scrollHandler();
            }, 10));
        }
    }


}

