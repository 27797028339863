export class Dropdown {
    isOpen: any;
    activeIndex: any;
    isCollapsed: boolean;
    container: HTMLElement;
    menu: HTMLElement;
    menuItems: any;
    trigger: HTMLButtonElement;

    constructor(container) {
        this.isOpen = false;
        this.activeIndex = undefined;
        this.container = container;
        this.trigger = container.querySelector("[data-dropdown-trigger]");
        this.menu = container.querySelector("[data-dropdown-list]");
        this.menuItems = container.querySelectorAll("[data-dropdown-list] li");
        this.trigger.addEventListener("click", this.toggle.bind(this));
        this.init();
    }

    init() {
        document.addEventListener("click", this.onClickOutside);
        document.addEventListener("keydown", this.onKeyEvent);
    }

    toggle() {
        this.isOpen = !this.isOpen;
        this.trigger.setAttribute("aria-expanded", this.isOpen);
        this.menu.setAttribute("aria-hidden", String(!this.isOpen));
        this.container.dataset.open = this.isOpen;
    }

    onClickOutside(e) {
        if (!this.isOpen) return;

        let targetElement = e.target;

        do {
            if (targetElement == this.container) return;

            targetElement = targetElement.parentNode;
        } while (targetElement);

        this.toggle();
    }

    onKeyEvent(e) {
        if (!this.isOpen) return;

        if (e.key === "Tab") {
            this.toggle();
        }

        if (e.key === "Escape") {
            this.toggle();
            this.trigger.focus();
        }

        if (e.key === "ArrowDown") {
            this.activeIndex =
                this.activeIndex < this.menuItems.length - 1 ? this.activeIndex + 1 : 0;
            this.menuItems[this.activeIndex].focus();
        }

        if (e.key === "ArrowUp") {
            this.activeIndex =
                this.activeIndex > 0 ? this.activeIndex - 1 : this.menuItems.length - 1;
            this.menuItems[this.activeIndex].focus();
        }
    }
}



