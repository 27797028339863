import * as CONSTANTS from './classes/constants';
import { SvgSprite } from './classes/svg-loader';
let debounce = require('lodash.debounce');
let throttle = require('lodash.throttle');
import { nodeListToArray } from './functions/helpers/nodeListToArray';
import { Accordion } from './classes/accordion';
import { DonationComponent } from './classes/donationComponent';
import { Alert } from './classes/alert'
import { Modal } from './classes/modal';
import { Gallery } from './classes/gallery';
import { ComponentAnimation } from './classes/component-animation';
import { mainNavigation } from './classes/main-navigation';
import { NavContainerToggle } from './classes/toggle-mobile-menu';
import { mobileNavigation } from './classes/mobile-navigation';
import { scrollToTop } from './classes/back-to-top'
import { stickyHeader } from './classes/sticky-navigation';
import { searchRedirect } from './classes/search-redirect';
import * as Player from "@vimeo/player/dist/player.js";
import { GoogleTranslate } from "./classes/google-translate";
import { Levels } from "./classes/membership-levels";
import { Dropdown } from "./classes/dropdown";

(() => {

    // SVG Sprite Inject
    if (!document.body.classList.contains('tnew-page')) {
        new SvgSprite('/static/img/sprite.svg');
        new SvgSprite('/static/img/waves.svg');
    }
    // Alerts
    const alerts = new Alert();

    // Block animations
    new ComponentAnimation();

    // Accordions
    const accordions = nodeListToArray(document.querySelectorAll('[data-accordion-list]'));
    accordions.forEach(accordion => {
        const oneOpen = accordion.getAttribute('data-one-open-at-a-time');
        new Accordion({
            container: accordion,
            oneOpenAtATime: oneOpen === 'True'
        });
    });


    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]')) as HTMLElement[];
    modals.forEach(modal => {
        new Modal(modal);
    });

    //Main navigation init
    let navElement = null;
    if (CONSTANTS.DESKTOP_NAV) {
        navElement = new mainNavigation(CONSTANTS.DESKTOP_NAV as HTMLElement);
    }

    //Mobile navigation init
    let mobileNavElement = null;
    if (CONSTANTS.MOBILE_NAV) {
        mobileNavElement = new mobileNavigation(CONSTANTS.MOBILE_NAV as HTMLElement);
    }

    new NavContainerToggle(CONSTANTS.MOBILE_TOGGLE);

    // Scroll to Top logic

    if (document.querySelector('[data-scrollTop]')) {
        scrollToTop();
    }

    // Sticker Header
    stickyHeader();

    //Google Translate Init
    new GoogleTranslate();

    // Gallery Init

    // Search Redirects
    let searchContainer = nodeListToArray(document.querySelectorAll('[data-search-container]'));
    if (searchContainer) {
        searchRedirect(searchContainer);
    }
    // Gallery Init
    // Note: it is recommended to use loop: false when creating a gallery with videos at the beginning/end because of duplicated slides.
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        new Gallery({
            containerClass: `${galleryClass}-${index}`,
            slidesPerView: 1
        })
    });

    //Donation Component
    new DonationComponent();

    // Membership Levels Init
    if (document.getElementById('memberships')) {
        const tabLists = nodeListToArray(document.querySelectorAll('[role=tablist]'));
        tabLists.forEach(tablist => {
            new Levels(tablist);
        });
    }


    const dropdownList = nodeListToArray(document.querySelectorAll("[data-dropdown]"));
    if (dropdownList) {
        dropdownList.forEach(dropdown => {
            new Dropdown(dropdown);
        });
    }

})();

