import { forEach } from 'lodash';
import { nodeListToArray } from '../functions/helpers/nodeListToArray';
import * as CONSTANTS from './constants';

export function stickyHeader() {
    window.addEventListener('load', function () {

        const header = document.querySelector('#siteHeader') as HTMLElement;
        const main = document.getElementById('main');
        const elementsToOffset = nodeListToArray(document.querySelectorAll('[data-header-offset]'));
        let headerDimensions = header.offsetHeight - header.offsetHeight;
        let lastScrollTop = 0;

        function adjustMainContentLocation() {
            main.style.paddingTop = header.offsetHeight.toString() + 'px';
        }

        function adjustStickyDependentElements() {
            if (elementsToOffset) {
                elementsToOffset.forEach((el) => {
                    el.style.top = header.offsetHeight.toString() + 'px';
                })
            }
        }

        // run on page load
        adjustMainContentLocation();
        adjustStickyDependentElements();
       

        function stickyDisplayHandler() {
            var currentPos = window.pageYOffset || document.documentElement.scrollTop;

            headerDimensions = header.offsetHeight;

            // handle header sticky
            if (currentPos === 0) {
                if (!CONSTANTS.GLOBAL_BODY.classList.contains('locked')) {
                    header.classList.add('sticky-show');
                    header.style.top = '0px';
                    header.classList.remove('sticky-top');
                }
            }
            // set current position to lastScrollTop
            lastScrollTop = currentPos;
        }

        window.addEventListener('resize', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
                adjustStickyDependentElements();
                adjustMainContentLocation();

            })
        );

        window.addEventListener('scroll', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
            })
        );
    });
}