import { max, min } from 'lodash';
import { nodeListToArray } from '../functions/helpers/nodeListToArray';


export class Levels {
    levels: Array<HTMLElement>;
    levelsList;
    levelPanels: any = [];
    dataLevelPanels: any = [];
    levelRanges: any = [];
    syncWithSelectList;
    selectList;
    joinCta: HTMLElement;
    joinInput: HTMLInputElement;
    showError: boolean;
    ariaAttributes: any = {
        selected: 'aria-selected',
        hidden: 'aria-hidden',
        controls: 'aria-controls'
    };

    constructor(levelsList: Array<Element>) {
        this.levelsList = levelsList;
        this.levels = nodeListToArray(this.levelsList.querySelectorAll('[role=tab]'));
        this.levelPanels = this.levelPanels;
        this.ariaAttributes = this.ariaAttributes;
        this.initInputElement();
        this.setLevelPanels();
        this.activateLevels();
        this.activatePriceRanges();
        this.activateLevelsList();
        this.currentLevel();
        this.dataLevelPanels = this.dataLevelPanels;
        this.levelRanges = this.levelRanges;
        this.showError = false;
        this.joinCta = document.getElementById('joinCta');
        this.joinCta.addEventListener('click', this.handleSubmission.bind(this));


    }

    updateSelectList(newSelectIndex: number) {
        if (this.syncWithSelectList)
            this.selectList.selectedIndex = newSelectIndex;
    }
    currentLevel() {
        const checkLevel = document.querySelector('#currentLevel') as HTMLInputElement;
        if (checkLevel) {
            let levelInt = checkLevel.value;
            let findLevel = document.getElementById(`Level_${levelInt}`)
            this.showPanel(findLevel);
            this.updateFormAmount(findLevel, false);
        }
        else {
            const defaultLevel = document.getElementById('Level_2');
            this.showPanel(defaultLevel);
            this.updateFormAmount(defaultLevel, false);
        }
    }

    activateLevels() {
        this.levels.forEach(level => {
            if (level.getAttribute("aria-selected") === 'true') {
                this.showPanel(level);
            }

            level.addEventListener('click', (e) => {
                this.showPanel(level);
                this.updateFormAmount(level, true);
            });
        });
    }

    activatePriceRanges() {
        this.levels.map(level => {
            const levelIndex = this.levels.indexOf(level);
            let levelRange = level.parentElement.querySelector('input[type=range]') as HTMLInputElement;
            let rangeMin: number = +levelRange.min;
            let rangeMax: number = +levelRange.max;
            let levelObj = {
                "level": levelIndex,
                "min": rangeMin,
                "max": rangeMax
            }
            this.levelRanges.push(levelObj);
        });
    }

    activateLevelsList() {
        this.levelsList.addEventListener('keydown', (e) => {
            let currentLevel;
            let newLevel;
            let newLevelIndex;
            //First, let's check if the element is in the levels array
            if (this.levels.indexOf(e.target) !== -1) {
                //If it is, set it as the current level number
                currentLevel = this.levels.indexOf(e.target);
                //If it's the left or up arrow and the current level is not the first level
                if ((e.keyCode === 37 || e.keyCode === 38) && currentLevel !== 0) {
                    e.preventDefault();
                    this.resetItems();
                    newLevelIndex = currentLevel - 1;
                    newLevel = this.levels[newLevelIndex];
                }
                //If it's the right or down arrow and the current level is not the last level
                if ((e.keyCode === 39 || e.keyCode === 40) && currentLevel !== this.levels.length - 1) {
                    e.preventDefault();
                    this.resetItems();
                    newLevelIndex = currentLevel + 1;
                    newLevel = this.levels[newLevelIndex];
                }
            }
            if (newLevel !== undefined) {
                this.updateLevel(newLevel);
                newLevel.focus();
                newLevel.click();
                this.updateSelectList(newLevelIndex);
            }
        });
    }
    resetAriaSelected() {

        this.levels.forEach(level => {
            level.setAttribute(this.ariaAttributes.selected, 'false');
        });
        this.dataLevelPanels.forEach(dataLevelPanel => {
            dataLevelPanel.setAttribute(this.ariaAttributes.hidden, 'true');
        });
    }
    resetAriaHidden() {

        this.levelPanels.forEach(levelPanel => {
            levelPanel.setAttribute(this.ariaAttributes.hidden, 'true');
        });
        this.dataLevelPanels.forEach(dataLevelPanel => {
            dataLevelPanel.setAttribute(this.ariaAttributes.hidden, 'true');
        });
    }
    resetLevelIndexes() {
        this.levels.forEach(level => {
            level.tabIndex = -1;
        });
        this.dataLevelPanels.forEach(dataLevelPanel => {
            dataLevelPanel.tabIndex = -1;
        });

    }
    resetItems() {
        this.resetAriaSelected();
        this.resetAriaHidden();
        this.resetLevelIndexes();
    }
    updateLevel(level: HTMLElement) {
        level.setAttribute(this.ariaAttributes.selected, 'true');
        level.tabIndex = 0;
    }
    updateDataLevel(dataLevel: HTMLElement) {
        if (dataLevel) {
            dataLevel.setAttribute(this.ariaAttributes.selected, 'true');
            dataLevel.tabIndex = 0;
        }
    }
    setLevelPanels() {
        this.levels.forEach(level => {
            const levelPanelIds = level.getAttribute(this.ariaAttributes.controls).split(',');
            const dataLevelPanelsIds = nodeListToArray(document.querySelectorAll('[data-level]'));
            levelPanelIds.forEach(levelPanelId => {
                if (document.getElementById(levelPanelId)) {
                    this.levelPanels.push(document.getElementById(levelPanelId));
                }
            });

            dataLevelPanelsIds.forEach(dataLevelPanel => {
                if (dataLevelPanel) {
                    this.dataLevelPanels.push(dataLevelPanel);
                }
            });

        });
    }
    showPanel(level: HTMLElement) {
        const levelToShowIDs = level.getAttribute(this.ariaAttributes.controls).split(',');
        const levelIndex = this.levels.indexOf(level);
        const dataLevel = document.querySelector(`[data-level='${levelIndex}']`) as HTMLElement;
        this.resetItems();
        this.updateLevel(level);
        this.updateDataLevel(dataLevel);
        let selectedContribType = document.getElementById("membershipTnewFundId") as HTMLInputElement;
        let contribType = (document.getElementById("tnewContribType_" + levelIndex) as HTMLInputElement).value;
        selectedContribType.value = contribType;
        if (this.syncWithSelectList)
            this.updateSelectList(levelIndex);
        levelToShowIDs.forEach(levelToShowID => {
            if (document.getElementById(levelToShowID)) {
                document.getElementById(levelToShowID).setAttribute(this.ariaAttributes.hidden, 'false');
            }
            if (dataLevel) {
                dataLevel.setAttribute(this.ariaAttributes.hidden, 'false');
            }

        });
    }
    initInputElement = () => {
        this.joinInput = document.getElementById('inputAmount') as HTMLInputElement;
        this.joinInput.addEventListener('blur', () => {
            this.handleInputCheck();
        })
    }

    updateFormAmount(level: HTMLElement, boolean) {
        const levelRange = level.parentElement.querySelector('input[type=range]') as HTMLInputElement;
        const input = document.getElementById('inputAmount') as HTMLInputElement;
        const rangeMin: number = +levelRange.min;
        const rangeMax: number = +levelRange.max;
        let mean = Math.floor((rangeMin + rangeMax) / 2);
        // Pass average value of membership level to input
        let previousAmountPaidInput = (document.getElementById("previousAmountPaid") as HTMLInputElement).value;
        let previousAmountPaid: number = +previousAmountPaidInput;
        mean -= previousAmountPaid;
        if (mean < 0) {
            mean = 0;
        }
        input.value = mean.toString();

        if (boolean = true) {
            this.handleInputCheck();
        }
    }

    handleInputCheck() {

        let y = this.joinInput.value;
        let x: number = +y;
        //add previous amount given to y. Pull from hidden input. Set to 0 if renewal/join
        let minimumAmountInput = (document.getElementById("previousAmountPaid") as HTMLInputElement).value;
        let minimumAmount: number = +minimumAmountInput;
        let adjustedamount = x + minimumAmount;

        const minimumToJoinInput = (document.getElementById("minimumToJoin") as HTMLInputElement).value; //pull from hidden input set in C#
        let minimumToJoin: number = +minimumToJoinInput;

        const maxLevelAmount = this.levelRanges[this.levelRanges.length - 1];

        if (x < minimumToJoin) {
            this.showError = true;
        }
        else if (x > maxLevelAmount.max) {
            this.showError = false;
            this.showPanel(document.getElementById(`Level_${maxLevelAmount.level}`));
        }
        else {
            this.showError = false;
            this.levelRanges.forEach(item => {
                if (adjustedamount >= item.min && adjustedamount <= item.max) {
                    this.showPanel(document.getElementById(`Level_${item.level}`));
                }
            })
        }
        this.handleFormError();

    }

    handleFormError() {
        const error = document.querySelector('[data-form-error]');

        if (this.showError != false) {
            error.classList.remove('hide');
            this.joinInput.classList.add('has-error');
        }
        else {
            error.classList.add('hide');
            this.joinInput.classList.remove('has-error');
        }
    }
    handleSubmission(e) {
        // On click, if error is present, prevent submission
        if (this.showError) {
            e.preventDefault();
        }

        // Check if input value is empty, show error if so
        if (this.joinInput.value == "" || this.joinInput.value == undefined) {
            this.showError = true;
            this.handleFormError();
        }

        // If input value is NOT empty/undefined, run input amount check
        else {
            this.handleInputCheck();

            // After input check, if minimum amount is hit and no errors, go off Sam
            if (this.showError != true) {
                this.addMembership(this.joinInput.value);
            }
        }
    }

    addMembership(membershipAmount) {
        let fundIdInput = (document.getElementById("membershipFundId") as HTMLInputElement);
        let tnewFundIdInput = (document.getElementById("membershipTnewFundId") as HTMLInputElement);
        let estatePlanningCheckbox = (document.getElementById("estatePlanningCheckbox") as HTMLInputElement).checked;
        let visualArtsCheckbox = (document.getElementById("visualArtsCheckbox") as HTMLInputElement).checked;
        let declineBenefitsCheckbox = (document.getElementById("declineBenefitsCheckbox") as HTMLInputElement).checked;

        if (fundIdInput && tnewFundIdInput) {
            let fundId = fundIdInput.value;
            let tnewFundId = tnewFundIdInput.value;
            if (fundId && fundId != "0" && tnewFundId && tnewFundId != "0") {
                var request = {
                    "amount": membershipAmount,
                    "fundId": fundId,
                    "tnewFundType": tnewFundId,
                    "estatePlanning": estatePlanningCheckbox,
                    "visualArtsSociety": visualArtsCheckbox,
                    "declineBenefits": declineBenefitsCheckbox
                }
                let membershipEndpoint = '/umbraco/api/membershipapi/addmembership';
                fetch(membershipEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                })
                    .then(response => response.json())
                    .then(data => this.handleMembershipResponse(data))
            }
        }


    }

    handleMembershipResponse(membershipResponse: any) {
        const heightOfHeader = 120;
        if (membershipResponse.membershipSuccess) {
            window.location.href = membershipResponse.redirectUrl;
        }
        else {
            const pageError = document.getElementById("page-error") as HTMLElement;
            pageError.classList.remove("hide");
            pageError.focus();
            window.scrollTo(0, heightOfHeader)
        }
    }
}