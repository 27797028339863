import { nodeListToArray } from '../functions/helpers/nodeListToArray';

export function searchRedirect(searchContainers) {
    this.searchContainers = searchContainers;

    if (this.searchContainers.length > 0) {
        this.searchContainers.forEach(searchContainer => {
            const searchInput = searchContainer.querySelector('[data-search-input]') as HTMLInputElement;
            const siteUrl = searchContainer.querySelector('[data-site-url]') as HTMLInputElement;
            const searchButton = searchContainer.querySelector('[data-search-submit]');
            searchButton.addEventListener('click', (e) => {
                e.preventDefault();
                let searchFieldValue = searchInput.value;
                let siteUrlValue = siteUrl.value;
                if (searchFieldValue !== "") {
                    let redirectUrl: any = `${siteUrlValue}/search?q=${searchFieldValue}`;
                    (window as any).location.href = redirectUrl;
                }
            });
          
        });
    }
}



