import { nodeListToArray } from '../functions/helpers/nodeListToArray';

export class NavContainerToggle {
    constructor(public htmlElement: any) {
        let menuToggle = document.querySelector('#menu-toggle'),
            mobileNavParent = document.querySelector("#header-nav-mobile"),
            menuContainer = document.querySelector('#nav-mobile')

        if (menuToggle) {
            menuToggle.addEventListener('click', (e) => {

                this.setHeaderBarOffset(menuContainer);

                menuContainer.classList.toggle('active');
                menuToggle.classList.toggle('active');
                mobileNavParent.classList.toggle('active');

            })
        }
    }

    setHeaderBarOffset(menuContainer) {
        const mobileNavContainer = document.getElementById('header-nav-mobile');
        const mobileInner = mobileNavContainer.querySelector('.strathmore-nav-inner').clientHeight;
    
    }

}
